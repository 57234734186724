<template>
    <div class="chart-criteria-screen-wrapper">
        
		<div class="page-title">
			
		</div>

        <div class="chart-input-section">
            <h3><u>Birth Details</u></h3>
            <div class="client-input" title="Chart Title">
                <table width="100%" border="0">
                    <tr>
                        <td width="100%" align="left">
                            &nbsp;
                            <!--<i class="far fa-user fa-lg"></i>-->
                            <font-awesome-icon :icon="['far', 'user']" size="lg" class="fontAwesomeIcon" />
                            <b>Chart Title</b>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <input 
                                type="text" 
                                size="30" 
                                minlength="2" 
                                maxlength="40" 
                                placeholder="Chart Title / Name / Initials" 
                                v-model="criteria.client" 
                                style="float:left;margin:5px;height:25px;vertical-align:top"/>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="date-input"  title="Birth Date">
                <table width="100%" border="0">
                    <tr>
                        <td width="100%" align="left">
                            &nbsp;
                            <!--<i class="far fa-calendar-alt fa-lg"></i>-->
                            <font-awesome-icon :icon="['fas', 'calendar-days']" size="lg" class="fontAwesomeIcon" />
                            <b>Birth Date</b>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select  name="month" v-model="criteria.month" @change="updateCriteriaDate" style="float:left;margin:5px">
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                            <select class="day" name="day" v-model="criteria.day" @change="updateCriteriaDate" style="float:left;margin:5px">
                                <option value="1">&nbsp;1</option>
                                <option value="2">&nbsp;2</option>
                                <option value="3">&nbsp;3</option>
                                <option value="4">&nbsp;4</option>
                                <option value="5">&nbsp;5</option>
                                <option value="6">&nbsp;6</option>
                                <option value="7">&nbsp;7</option>
                                <option value="8">&nbsp;8</option>
                                <option value="9">&nbsp;9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </select>
                            <input 
								type="number" 
								name="year" 
								min="-12999" 
								max="16799" 
								step="1" 
								placeholder="YEAR" 
								v-model="criteria.year" 
								@change="updateCriteriaDate" 
								style="float:left;margin:5px;height:25px;vertical-align:top"/>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="time-input"  title="Birth Time">
                <table width="100%" border="0">
                    <tr>
                        <td width="100%" align="left">
                            &nbsp;
                            <!--<i class="far fa-clock fa-lg"></i>-->
                            <font-awesome-icon :icon="['far', 'clock']" size="lg" class="fontAwesomeIcon" />
                            <b>Birth Time</b>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select class="hour" name="hour" v-model="criteria.hour" @change="updateCriteriaDate" style="float:left;margin:5px">
                                <option value="0">&nbsp;0 - 12 AM</option>
                                <option value="1">&nbsp;1 - 01 AM</option>
                                <option value="2">&nbsp;2 - 02 AM</option>
                                <option value="3">&nbsp;3 - 03 AM</option>
                                <option value="4">&nbsp;4 - 04 AM</option>
                                <option value="5">&nbsp;5 - 05 AM</option>
                                <option value="6">&nbsp;6 - 06 AM</option>
                                <option value="7">&nbsp;7 - 07 AM</option>
                                <option value="8">&nbsp;8 - 08 AM</option>
                                <option value="9">&nbsp;9 - 09 AM</option>
                                <option value="10">10 - 10 AM</option>
                                <option value="11">11 - 11 AM</option>
                                <option value="12">12 - 12 PM</option>
                                <option value="13">13 - 01 PM</option>
                                <option value="14">14 - 02 PM</option>
                                <option value="15">15 - 03 PM</option>
                                <option value="16">16 - 04 PM</option>
                                <option value="17">17 - 05 PM</option>
                                <option value="18">18 - 06 PM</option>
                                <option value="19">19 - 07 PM</option>
                                <option value="20">20 - 08 PM</option>
                                <option value="21">21 - 09 PM</option>
                                <option value="22">22 - 10 PM</option>
                                <option value="23">23 - 11 PM</option>
                            </select>
                            <select class="minute" name="minute" v-model="criteria.minute" @change="updateCriteriaDate" style="float:left;margin:5px">
                                <option value="0">00</option>
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                                <option value="32">32</option>
                                <option value="33">33</option>
                                <option value="34">34</option>
                                <option value="35">35</option>
                                <option value="36">36</option>
                                <option value="37">37</option>
                                <option value="38">38</option>
                                <option value="39">39</option>
                                <option value="40">40</option>
                                <option value="41">41</option>
                                <option value="42">42</option>
                                <option value="43">43</option>
                                <option value="44">44</option>
                                <option value="45">45</option>
                                <option value="46">46</option>
                                <option value="47">47</option>
                                <option value="48">48</option>
                                <option value="49">49</option>
                                <option value="50">50</option>
                                <option value="51">51</option>
                                <option value="52">52</option>
                                <option value="53">53</option>
                                <option value="54">54</option>
                                <option value="55">55</option>
                                <option value="56">56</option>
                                <option value="57">57</option>
                                <option value="58">58</option>
                                <option value="59">59</option>
                            </select>
                            <select class="second" name="second" v-model="criteria.second" @change="updateCriteriaDate" style="float:left;margin:5px">
                                <option value="0">00</option>
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                                <option value="32">32</option>
                                <option value="33">33</option>
                                <option value="34">34</option>
                                <option value="35">35</option>
                                <option value="36">36</option>
                                <option value="37">37</option>
                                <option value="38">38</option>
                                <option value="39">39</option>
                                <option value="40">40</option>
                                <option value="41">41</option>
                                <option value="42">42</option>
                                <option value="43">43</option>
                                <option value="44">44</option>
                                <option value="45">45</option>
                                <option value="46">46</option>
                                <option value="47">47</option>
                                <option value="48">48</option>
                                <option value="49">49</option>
                                <option value="50">50</option>
                                <option value="51">51</option>
                                <option value="52">52</option>
                                <option value="53">53</option>
                                <option value="54">54</option>
                                <option value="55">55</option>
                                <option value="56">56</option>
                                <option value="57">57</option>
                                <option value="58">58</option>
                                <option value="59">59</option>
                            </select>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="location-input" title="Birth Location">
                <table width="100%" border="0">
                    <tr>
                        <td width="100%" align="left">
                            &nbsp;
                            <!--<i class="fas fa-map-marker-alt fa-lg"></i>&nbsp;-->
                            <font-awesome-icon :icon="['fas', 'location-dot']" size="lg" class="fontAwesomeIcon" />
                            <b>Birth Location</b>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">    
							<WorldCitySearch :textFieldSize="300" :criteria="criteria" @selectedWorldCity="setWorldCity"></WorldCitySearch>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="map-image" align="center">
                <table width="100%" border="0">
                    <tr>
                        <td width="100%" align="left">
                            <font-awesome-icon :icon="['fas', 'map-location-dot']" size="lg" class="fontAwesomeIcon" />
                            <b>Map</b>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">    
                            <iframe
                                width="300"
                                height="300"
                                style="border:0"
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                                :src="locationMapUrl">
                            </iframe> 
                        </td>
                    </tr>
                </table>
            </div>
            <br/>
            <div class="custom-location-input" title="Adjust Location or Timezone if needed">
                <h3><u>Adjust Location / Timezone if needed</u></h3>
                <table width="100%" border="0">
                    <tr>
                        <td width="100%" align="left">
                            &nbsp;
                            <b>Latitude</b>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">    
                            &nbsp;
                            <input 
                                type="number" 
                                min="-90"
                                max="90"
                                step="any"
                                class="location"
                                style="height:25px;width:130px;vertical-align:top"
                                v-model="criteria.latitude"/>
                            &nbsp;    
                            <span style="color:red" v-if="!isValidLatitude()">Invalid Latitude</span>    
                        </td>
                    </tr>
                    <tr height="10px">
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            &nbsp;
                            <b>Longitude</b>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">    
                            &nbsp;<input 
                                type="number" 
                                min="-180"
                                max="180"
                                step="any"
                                class="location"
                                style="height:25px;width:130px;vertical-align:top"
                                v-model="criteria.longitude"/>
                            &nbsp;<span style="color:red" v-if="!isValidLongitude()">Invalid Longitude</span>
                        </td>
                    </tr>
                    <tr height="10px">
                        <td width="100%" align="left"></td>
                    </tr>
                </table>
                <table width="100%" border="0">
                    <tr>
                        <td width="100%" align="left">
                            &nbsp;
                            <b>UTC Offset Minutes</b>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">    
                            &nbsp;<input 
                                type="number" 
                                min="-1440"
                                max="1440"
                                step="1"
                                class="location"
                                placeholder="Mins"
                                style="height:25px;vertical-align:top"
                                v-model="criteria.timeOffsetMinutes"
                                @change="criteria.timeOffset=(new Number(criteria.timeOffsetMinutes)/60.0)"/>
                        </td>
                    </tr>
                    <tr height="10px">
                        <td width="100%" align="left"></td>
                    </tr>
                </table>
                <table width="100%" border="0">
                    <tr height="20px">
                        <td width="100%" align="left">&nbsp;<b>Timezone:</b>&nbsp;&nbsp;<b>{{getUTCString()}}</b></td>
                    </tr>
                </table>
            </div>
            <div class="dst-adjust-warning">
                <table width="100%" border="0">
                    <tr>
                        <td width="20%" align="center">
                            <!--<i class="fas fa-exclamation-triangle fa-lg"></i>-->
                            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="lg" class="fontAwesomeIcon" />
                            Please adjust UTC offset minutes according to DST observed at birth time
                        </td>
                    </tr>
                </table>
            </div>
            <br/>
        </div>
        <br/>
        <div class="chart-preferences-section">
            <div class="preferences-input" title="Preferences">
                <h3><u>Preferences</u></h3>
                <table width="100%" border="0" class="preferences-input-table">
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>Chart Style</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select  name="cs" v-model="criteria.chartStyle" style="float:left;margin:5px">
                                <option value="0"> South Indian</option>
                                <option value="1">North Indian</option>
                                <option value="2">East Indian</option>
                            </select>
                        </td>
                    </tr>
                    <tr height="20px">
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>Ayanamsa</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select  name="aynamsa" v-model="criteria.ayanamsa" style="float:left;margin:5px;width:300px">
                                <option v-for="(index, name) in ayanamsas" :key="name" :value="name">
                                    <pre>{{index}}</pre>
                                </option>
                            </select>
                        </td>
                    </tr>

                    <tr height="20px">
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>House System</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select  name="bhavaChart" v-model="criteria.bhavaChartType" style="float:left;margin:5px">
                                <option value="66">ALCABITIUS</option>
                                <option value="67">CAMPANUS</option>
                                <option value="69">EQUAL</option>
                                <option value="72">HORIZONTAL</option>
                                <option value="75">KOCH</option>
                                <option value="77">MORINUS</option>
                                <option value="79">PORPHYRIUS / SRIPATI</option>
                                <option value="80">PLACIDUS / KP SYSTEM</option>
                                <option value="82">REGIOMONTANUS</option>
                                <option value="84">POLICH_PAGE</option>
                                <option value="85">KRUSINSKI</option>
                                <option value="88">MERIDIAN</option>
                            </select>
                        </td>
                    </tr>

                    <tr height="20px">
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>Days In Year</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select  name="ddiy" v-model="criteria.dasaBuktiDaysInYear" style="float:left;margin:5px">
                                <option value="365.256">365.256 : Sidereal Year</option>
                                <option value="365.242">365.242 : Tropical Year</option>
                                <option value="360">360.000 : Savana Year</option>
                            </select>
                        </td>
                    </tr>

                    <tr height="20px">
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>Aspects &#177; Degree Range</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select  name="adl" v-model="criteria.aspectDegreeLimit" style="float:left;margin:5px">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                            </select>
                        </td>
                    </tr>
                    <tr height="20px">
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>Conjunction &#177; Degree Range</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select  name="cdl" v-model="criteria.conjunctionDegreeLimit" style="float:left;margin:5px">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                            </select>
                        </td>
                    </tr>

                    <tr height="20px">
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>Maandi Rising Calculation</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select  name="topo" v-model="criteria.maandiCalculationMethod" style="float:left;margin:5px;width:95%;">
                                <option value="1">( (Ghatikas of the day/night X a constant rising ghatika of the day/night) &div; 30 )</option>
                                <option value="2">The day/night is divided into 8 equal parts & at the start of Saturn's part </option>
                                <option value="3">The day/night is divided into 8 equal parts & at the middle of Saturn's part </option>
                                <option value="4">The day/night is divided into 8 equal parts & at the end of Saturn's part </option>
                                <option value="5">SUN degree of the given date + a constant rising degree of the day/night</option>
                            </select>
                        </td>
                    </tr>

                    <tr height="20px">
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>Planetary Positions</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select  name="topo" v-model="criteria.topocentricPlanets" style="float:left;margin:5px">
                                <option value="0">Geocentric </option>
                                <option value="1">Topocentric</option>
                            </select>
                        </td>
                    </tr>

                    <tr height="20px">
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>Rahu Node</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select  name="rkn" v-model="criteria.rahuNode" style="float:left;margin:5px">
                                <option value="10">Mean Node</option>
                                <option value="11">True Node</option>
                            </select>
                        </td>
                    </tr>

                    <tr height="20px">
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>Nutation</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select  name="nutation" v-model="criteria.nutationRequired" style="float:left;margin:5px">
                                <option value="1">Include - True Equinox & Obliquity</option>
                                <option value="0">Exclude - Mean Equinox & Obliquity</option>
                            </select>
                        </td>
                    </tr>


                    <tr height="20px">
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>Sunrise</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select  name="sunrise" v-model="criteria.hinduSunrise" style="float:left;margin:5px">
                                <option value="1">Hindu / Vedic</option>
                                <option value="0">Astronomical</option>
                            </select>
                        </td>
                    </tr>



                    <tr height="20px">
                        <td width="100%" align="left"></td>
                    </tr>

                </table>
            </div>
            <br/>
            <div align="center">
                <button @click="showChart">&nbsp;Show Chart&nbsp;</button>
            </div>
            <br/>
        </div>
    </div>
</template>

<script>

import HTTP from "../http-axios";
import GoogleAPICredentials from "../google-api-credentials";
import { getUTCString, isValidLatitude, isValidLongitude, queryString, isBlank } from "../util.js";
import WorldCitySearch from "./widget-city-search/WorldCitySearch.vue";

export default {
    name: "ChartCriteria",
    components: { WorldCitySearch },
    beforeCreate() {
        if ( localStorage.getItem("nctz") ) {
            // A location with timezone already exists
        } else {
            // Clear the existing location data
            // We do not have timezone for existing location
            // Lets start afresh with default location
            // Let the User pick their choice of location
            localStorage.removeItem("ncloc");
            localStorage.removeItem("nclat");
            localStorage.removeItem("nclong");
            localStorage.removeItem("nctimeOffsetMinutes");
            localStorage.removeItem("nctimeOffset");
            localStorage.removeItem("nctz");
        }
    },
    data() {
        return {
            criteria: {
                client: this.$route.query.client ? this.$route.query.client : localStorage.getItem("ncclient") ? localStorage.getItem("ncclient") : "",

                year: this.$route.query.year ? this.$route.query.year : localStorage.getItem("ncyear") ? Number.parseInt(localStorage.getItem("ncyear")) : new Date().getFullYear(),
                month: this.$route.query.month ? this.$route.query.month : localStorage.getItem("ncmonth") ? Number.parseInt(localStorage.getItem("ncmonth")) : new Date().getMonth() + 1,
                day: this.$route.query.day ? this.$route.query.day : localStorage.getItem("ncday") ? Number.parseInt(localStorage.getItem("ncday")) : new Date().getDate(),

                hour: this.$route.query.hour ? this.$route.query.hour : localStorage.getItem("nchour") ? Number.parseInt(localStorage.getItem("nchour")) : new Date().getHours(),
                minute: this.$route.query.minute ? this.$route.query.minute : localStorage.getItem("ncminute") ? Number.parseInt(localStorage.getItem("ncminute")) : new Date().getMinutes(),
                second: this.$route.query.second ? this.$route.query.second : localStorage.getItem("ncsecond") ? Number.parseInt(localStorage.getItem("ncsecond")) : 0,

                location: this.$route.query.location ? this.$route.query.location : localStorage.getItem("ncloc") ? localStorage.getItem("ncloc") : "Tiruvannamalai, Tamil Nadu, India",
                latitude: this.$route.query.latitude ? this.$route.query.latitude : localStorage.getItem("nclat") ? new Number(localStorage.getItem("nclat")) : 12.2253,
                longitude: this.$route.query.longitude ? this.$route.query.longitude : localStorage.getItem("nclong") ? new Number(localStorage.getItem("nclong")) : 79.0747,
                timeOffsetMinutes: this.$route.query.timeOffsetMinutes ? this.$route.query.timeOffsetMinutes : localStorage.getItem("nctimeOffsetMinutes") ? Number.parseInt(localStorage.getItem("nctimeOffsetMinutes")) : 330,
                timeOffset: this.$route.query.timeOffset ? this.$route.query.timeOffset : localStorage.getItem("nctimeOffset") ? new Number(localStorage.getItem("nctimeOffset")) : 5.5,
                timezone: this.$route.query.timezone ? this.$route.query.timezone : localStorage.getItem("nctz") ? localStorage.getItem("nctz") : "Asia/Kolkata",

                chartStyle: this.$route.query.chartStyle ? this.$route.query.chartStyle : localStorage.getItem("ncchartStyle") ? Number.parseInt(localStorage.getItem("ncchartStyle")) : 0,
                ayanamsa: this.$route.query.ayanamsa ? this.$route.query.ayanamsa : localStorage.getItem("ncayanamsa") ? Number.parseInt(localStorage.getItem("ncayanamsa")) : 1,
                bhavaChartType: this.$route.query.bhavaChartType ? this.$route.query.bhavaChartType : localStorage.getItem("ncbhavaChart") ? Number.parseInt(localStorage.getItem("ncbhavaChart")) : 79,
                dasaBuktiDaysInYear: this.$route.query.dasaBuktiDaysInYear ? this.$route.query.dasaBuktiDaysInYear : localStorage.getItem("ncddiy") ? Number.parseFloat(localStorage.getItem("ncddiy")) : 365.256,
                aspectDegreeLimit: this.$route.query.aspectDegreeLimit ? this.$route.query.aspectDegreeLimit : localStorage.getItem("ncadl") ? Number.parseFloat(localStorage.getItem("ncadl")) : 15,
                conjunctionDegreeLimit: this.$route.query.conjunctionDegreeLimit ? this.$route.query.conjunctionDegreeLimit : localStorage.getItem("nccdl") ? Number.parseFloat(localStorage.getItem("nccdl")) : 20,
                rahuNode: this.$route.query.rahuNode ? this.$route.query.rahuNode : localStorage.getItem("ncrkn") ? Number.parseInt(localStorage.getItem("ncrkn")) : 10,
                topocentricPlanets: this.$route.query.topocentricPlanets ? this.$route.query.topocentricPlanets : localStorage.getItem("nctopo") ? Number.parseInt(localStorage.getItem("nctopo")) : 0,
                nutationRequired: this.$route.query.nutationRequired ? this.$route.query.nutationRequired : localStorage.getItem("ncnutation") ? Number.parseInt(localStorage.getItem("ncnutation")) : 1,
                hinduSunrise: this.$route.query.hinduSunrise ? this.$route.query.hinduSunrise : localStorage.getItem("ncsunrise") ? Number.parseInt(localStorage.getItem("ncsunrise")) : 1,
                maandiCalculationMethod: this.$route.query.maandiCalculationMethod ? this.$route.query.maandiCalculationMethod : localStorage.getItem("ncmaandiCalculation") ? Number.parseInt(localStorage.getItem("ncmaandiCalculation")) : 1,

                username: "",
                email: "",
                ipAddress: "",
                deviceType: "SS",
                chartSize: 2,
            },
            ayanamsas: {},
            locationMapUrl: "",
        };
    },
    created() {
        // Clear the request query string parameters
        // We have captured query string values during during data intialization process
        // Now we do NOT need it again while coming back to this component
        this.$route.query = {};

        this.setLocationMapUrl();
        this.getAyanamsas();
    },
    mounted() {},
    methods: {
        setLocationMapUrl: function () {
            this.locationMapUrl = "https://www.google.com/maps/embed/v1/place?q=" + this.criteria.location + "&zoom=8&maptype=roadmap" + "&key=" + GoogleAPICredentials();
        },
        isCriteriaSet: function () {
            return this.isValidLatitude() && this.isValidLongitude();
        },
        getUTCString: function () {
            return getUTCString(this.criteria.timeOffsetMinutes);
        },
        isValidChartTitle: function () {
            return !isBlank(this.criteria.client);
        },
        isValidLatitude: function () {
            return isValidLatitude(new String(this.criteria.latitude).trim());
        },
        isValidLongitude: function () {
            return isValidLongitude(new String(this.criteria.longitude).trim());
        },
        queryString: function (obj) {
            return queryString(obj);
        },
        handleBlur() {
            this.$refs.autocomplete.value = this.criteria.location;
        },
        getAyanamsas: function () {
            HTTP()
                .get(`/api/data/ayanamsas`)
                .then((response) => {
                    this.ayanamsas = response.data;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        showChart: function () {
            if (this.isCriteriaSet()) {
                this.$emit("criteria", this.criteria);
            }
        },
        getCriteriaDate: function () {
            return new Date(this.criteria.year, this.criteria.month - 1, this.criteria.day, this.criteria.hour, this.criteria.minute, 0);
        },
        updateCriteriaDate: function () {
            // Year value validation
            if (this.criteria.year) {
                var selectedYear = Number.parseInt(this.criteria.year);
                if (selectedYear < -12999 || selectedYear > 16799) {
                    window.alert("Unsupported year : " + selectedYear + ". Valid year range : 13000 BC - 16798 AD");
                    this.criteria.year = localStorage.getItem("ncyear") ? Number.parseInt(localStorage.getItem("ncyear")) : new Date().getFullYear();
                }
            } else {
                this.criteria.year = new Date().getFullYear();
            }

            // set selected date & time in local storage
            localStorage.setItem("ncyear", this.criteria.year);
            localStorage.setItem("ncmonth", this.criteria.month);
            localStorage.setItem("ncday", this.criteria.day);
            localStorage.setItem("nchour", this.criteria.hour);
            localStorage.setItem("ncminute", this.criteria.minute);
            localStorage.setItem("ncsecond", this.criteria.second);

            // Check for timeOffset Change
            this.checkTimeOffsetChangeForDate();
        },
        checkTimeOffsetChangeForDate: function() {
            HTTP().post(`/api/data/city/timeOffset?city=` + this.criteria.location + '&timezone=' + this.criteria.timezone 
                        + '&year=' + this.criteria.year + '&month=' + this.criteria.month + '&day=' + this.criteria.day 
                        + '&hour=' + this.criteria.hour + '&minute=' + this.criteria.minute + '&second=' + this.criteria.second)
            .then(response => {
                var cityTimeOffset = response.data
                // console.log('cityTimeOffset: ' + JSON.stringify(cityTimeOffset))
                if ( cityTimeOffset ) {
                    this.criteria.timeOffset = cityTimeOffset
                    this.criteria.timeOffsetMinutes = cityTimeOffset * 60.0
                    localStorage.setItem("nctimeOffset", this.criteria.timeOffset);
                    localStorage.setItem("nctimeOffsetMinutes", this.criteria.timeOffsetMinutes);
                }
            })
            .catch(e => {
                console.log(e)
            })
        },
        GoogleAPICredentials: function () {
            return GoogleAPICredentials();
        },
        setWorldCity: function (worldCity) {
            this.criteria.location = worldCity.city;
            this.criteria.latitude = worldCity.lat;
            this.criteria.longitude = worldCity.lng;
            this.criteria.timeOffset = worldCity.timeOffset;
            this.criteria.timeOffsetMinutes = worldCity.timeOffsetMinutes;
            this.criteria.timezone = worldCity.timezone;

            // keep this location info in local storage
            localStorage.setItem("ncloc", this.criteria.location);
            localStorage.setItem("nclat", this.criteria.latitude);
            localStorage.setItem("nclong", this.criteria.longitude);
            localStorage.setItem("nctimeOffset", this.criteria.timeOffset);
            localStorage.setItem("nctimeOffsetMinutes", this.criteria.timeOffsetMinutes);
            localStorage.setItem("nctz", this.criteria.timezone);

            // Update Location Map Image for new location
            this.setLocationMapUrl();

            // debug
            // console.log('mcc:city: ' + this.criteria.location);
            // console.log('mcc:lat: ' + this.criteria.latitude);
            // console.log('mcc:lng: ' + this.criteria.longitude);
            // console.log('mcc:timeOffset: ' + this.criteria.timeOffset);
            // console.log('mcc:timeOffsetMinutes: ' + this.criteria.timeOffsetMinutes);
            // console.log('mcc:timezone: ' + this.criteria.timezone);            
        },
    },
    updated() {},
    beforeUnmount() {
        // Capture chart settings before leaving the component
        localStorage.setItem("ncclient", this.criteria.client);
        localStorage.setItem("ncyear", this.criteria.year);
        localStorage.setItem("ncmonth", this.criteria.month);
        localStorage.setItem("ncday", this.criteria.day);
        localStorage.setItem("nchour", this.criteria.hour);
        localStorage.setItem("ncminute", this.criteria.minute);
        localStorage.setItem("ncsecond", this.criteria.second);
        localStorage.setItem("ncloc", this.criteria.location);
        localStorage.setItem("nclat", this.criteria.latitude);
        localStorage.setItem("nclong", this.criteria.longitude);
        localStorage.setItem("nctimeOffsetMinutes", this.criteria.timeOffsetMinutes);
        localStorage.setItem("nctimeOffset", this.criteria.timeOffset);
        localStorage.setItem("nctz", this.criteria.timezone);
        localStorage.setItem("ncchartStyle", this.criteria.chartStyle);
        localStorage.setItem("ncayanamsa", this.criteria.ayanamsa);
        localStorage.setItem("ncbhavaChart", this.criteria.bhavaChartType);
        localStorage.setItem("ncddiy", this.criteria.dasaBuktiDaysInYear);
        localStorage.setItem("ncrkn", this.criteria.rahuNode);
        localStorage.setItem("nctopo", this.criteria.topocentricPlanets);
        localStorage.setItem("ncnutation", this.criteria.nutationRequired);
        localStorage.setItem("ncsunrise", this.criteria.hinduSunrise);
        localStorage.setItem("nccdl", this.criteria.conjunctionDegreeLimit);
        localStorage.setItem("ncadl", this.criteria.aspectDegreeLimit);
        localStorage.setItem("ncmaandiCalculation", this.criteria.maandiCalculationMethod);
    },
}
</script>


<style scoped>

* {
  font-family: sans-serif; 
}

.pointer {
  cursor: pointer
}

.chart-criteria-screen-wrapper {
  border: 0px solid;
  width: 100%;
  height: 70%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
}

.page-title {
	border: 0px solid;
	width: 100%;
    margin-bottom: 0px;
}

.chart-input-section {
  border: 0px solid;
  border-color: #a0a0a0;
  width: 90%;
  align-content: center;
  border-radius: 10px;
  margin-top: 10px;
}

.chart-preferences-section {
  border: 0px solid;
  border-color: #a0a0a0;
  width: 90%;
  align-content: center;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

table, td {
  height: 30px;
  padding: 2px;
}

.client-input {
  width: 100%;
  margin-bottom: 30px;
}

.date-input {
  width: 100%;
  margin-bottom: 30px;
}

.time-input {
  width: 100%;
  margin-bottom: 30px;
}

.location-input {
  width: 100%;
  margin-bottom: 30px;
}

.custom-location-input{
  margin-top: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.dst-adjust-warning {
  width: 100%;
  margin-top: 20px;
}

.fas {
  padding-right: 5px;
  pointer-events: none;
}

.far {
  padding-right: 5px;
  pointer-events: none;
}

.fontAwesomeIcon {
  padding-right: 10px;
  pointer-events: none;
}

input[type=text] {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 10px;  
  padding: 15px 5px;
  margin: 2px 0;
  box-sizing: border-box;
  border: 2px solid #087F8C;
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: none;
}

input[type=text]:focus {
  border: 2px solid #ACD8AA;
}

input[type=number] {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 10px;  
  padding: 15px 5px;
  margin: 2px 0;
  box-sizing: border-box;
  border: 2px solid #087F8C;
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: none;
}

input[type=number]:focus {
  border: 2px solid #ACD8AA;
}

button {
  background-color: #087F8C;
  color: #FFFFFF;
  font-weight: bold;
  border: none;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  text-align: center;
}

select {
  margin: 1px;
  height: 35px;
  cursor:pointer;
  display:inline-block;
  position:relative;
  font:normal 14px;
  color:black;
  border:2px solid  #087F8C;
  border-color: 1px solid #087F8C;
  outline: none;
  border-radius: 10px;  
}

select:focus {
  border: 2px solid #ACD8AA;
}


</style>
